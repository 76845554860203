// import { ArctileDetailsInt, ArticleResInt, ArticleStateInt } from "@/types/aritcleInterface";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  count: 0,
  privacyData: [],
  isLoaded: false,
};

export const authorSlice = createSlice({
  name: "privacy",
  initialState,
  reducers: {
    getPrivacy: (state, action: PayloadAction<any>) => {
      state.count = action.payload ? action.payload?.count : 0;
      state.privacyData = action.payload ? action.payload : [];
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload;
    },
  },
});
export const { getPrivacy, setLoader } = authorSlice.actions;
export default authorSlice.reducer;
