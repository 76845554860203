import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { RootState } from "store/store";
import atriLogo from "assets/images/atri-logo.png";
import { ROUTES_PATH } from "utils/constant";
import Header from "./component/Header";
import Sidebar from "./component/Sidebar";

export const PrivateRoute = () => {
  const { token } = useAppSelector((state: RootState) => state.auth);
  const { isSideBar } = useAppSelector((state: RootState) => state.layout);

  return token ? (
    <div id="wrapper">
      <Header />
      <div className="main">
      
      <div className={`slide_overlay ${isSideBar ? "open" : ""}`}></div>
        <aside className={`side ${isSideBar ? "slide" : ""}`}>
          <div className="pd_home_logo">
            <img src={atriLogo} alt="logo" />
          </div>
          <Sidebar />
        </aside>
        <div className={`content ${isSideBar ? "" : "hide_side"}`}>
          <Outlet />
        </div>
      </div>
    </div>
  ) : (
    <Navigate to={ROUTES_PATH.LOGIN} />
  );
};

export const PublicRoute = () => {
  const { token } = useAppSelector((state: RootState) => state.auth);
  return !token ? <Outlet /> : <Navigate to={ROUTES_PATH.DASHBOARD} />;
};
