import { lazy } from "react";
import { ROUTES_PATH } from "../utils/constant";

const Login = lazy(() => import("pages/OnBoarding/Login"));
const PagePublished = lazy(()=> import("pages/PagePublished"))
const Authors = lazy(() => import("pages/Authors"));
const User = lazy(()=> import("pages/User"))
const Home = lazy(() => import("pages/Dashboard"));
const NewResearchPaper = lazy(() => import("pages/NewResearchPaper"));
const EditNewResearchPaper = lazy(() => import("pages/NewResearchPaper/EditRearchPaper"));
const ResetPassword = lazy(() => import("pages/OnBoarding/ResetPassword"));
const ForgotPassword = lazy(() => import("pages/OnBoarding/ForgotPassword"));
const CreateAuthor = lazy(() => import("pages/Authors/CreateAuthor"));
const EditAuthor = lazy(() => import("pages/Authors/EditAuthor"));
const Role = lazy(() => import("pages/Role"));
const AddRole  = lazy(() => import("pages/Role/CreateRole"))
const EditRole = lazy(() => import("pages/Role/EditRole"))
const Profile = lazy(() => import("pages/Profile"))
const PrivacyPolicy  = lazy(() => import("pages/PrivacyPolicy"))
const TermsCondition = lazy(() => import("pages/TermsCondition"))

const Adduser = lazy(()=> import("pages/User/AddUser"))
const EditUser = lazy(()=> import("pages/User/EditUser"))

export const publicRoutes = [
  {
    key: "login",
    path: ROUTES_PATH.LOGIN,
    component: Login,
  },
  {
    key: "forgotpassword",
    path: ROUTES_PATH.FORGOTPASSWORD,
    component: ForgotPassword,
  },
  {
    key: "resetpassword",
    path: ROUTES_PATH.RESETPASSWORD,
    component: ResetPassword,
  },
];

export const privateRoutes = [
  {
    key: "home",
    path: ROUTES_PATH.DASHBOARD,
    component: Home,
  },
  {
    key: "newresearchpaper",
    path: ROUTES_PATH.NEWRESEARCHPAPER,
    component: NewResearchPaper,
  },
  {
    key: "editnewresearchpaper",
    path: `${ROUTES_PATH.EDITNEWRESEARCHPAPER}/:id`,
    component: EditNewResearchPaper,
  },
  {
    key: "authors",
    path: `${ROUTES_PATH.AUTHORS}`,
    component: Authors,
  },
  {
    key: "createauthor",
    path: `${ROUTES_PATH.CREATEAUTHOR}`,
    component: CreateAuthor,
  },

  {
    key: "privacypolicy",
    path: `${ROUTES_PATH.PRIVACYPOLICY}`,
    component: PrivacyPolicy,
  },

  {
    key: "privacypolicy",
    path: `${ROUTES_PATH.TERMSCONDTION}`,
    component: TermsCondition,
  },

  // PrivacyPolicy

  {
    key: "editauthor",
    path: `${ROUTES_PATH.EDITAUTHOR}/:id`,
    component: EditAuthor,
  },
  // CreateAuthor
  {
    key: "user",
    path: `${ROUTES_PATH.USER}`,
    component: User,
  },
  {
    key: "pagepublished",
    path: `${ROUTES_PATH.PAGEPUBLISHED}`,
    component: PagePublished,
  },
  {
    key: "adduser",
    path: `${ROUTES_PATH.ADDUSER}`,
    component: Adduser,
  },  
  {
    key: "edituser",
    path: `${ROUTES_PATH.EDITUSER}/:id`,
    component: EditUser,
  },  
  {
    key: "roles",
    path: `${ROUTES_PATH.ROLE}`,
    component: Role,
  }, 
  {
    key: "addroles",
    path: `${ROUTES_PATH.ADDROLE}`,
    component: AddRole,
  }, 
  {
    key: "editroles",
    path: `${ROUTES_PATH.EDITROLE}/:id`,
    component: EditRole,
  }, 
  {
    key: "profile",
    path: `${ROUTES_PATH.PROFILE}`,
    component: Profile,
  }, 
];
