// import { ArctileDetailsInt, ArticleResInt, ArticleStateInt } from "@/types/aritcleInterface";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  userCount:0,
  count: 0,
  results: [],
  maxscore: null,
  isLoaded: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUsers: (state, action: PayloadAction<any>) => {
      state.results = action.payload ? action.payload.result : [];
      state.count = action.payload ? action.payload.count : 0;
      state.maxscore = action.payload ? action.payload.maxscore : 0;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload;
    },
    updateUserLocally: (state, action: PayloadAction<any>) => {
      state.results = state.results.filter((list:any)=> list.id !== action.payload); 
      state.count = state.results.length;
    },
    
  },
});
export const { getUsers, setLoader,updateUserLocally } = userSlice.actions;
export default userSlice.reducer;
