// const ComponentLoader = () => {
//   return (
//     <div className="linear-activity">
//       <div className="indeterminate">loading .....</div>
//     </div>
//   );
// };
// export default ComponentLoader;


import  { useState } from "react";

import { isLoading } from "utils/CommonService";

import "./loader.scss";

const ComponentLoader = () => {
  const [loading, setLoading] = useState(false);

  isLoading.subscribe((val:any) => {
    if(loading !== val) setLoading(val)
  })

  return (
    <div className="progress-loader" hidden={!loading}>
      <div id="overlay"></div>
      <div className="ring">
        <span></span>
      </div>
    </div>
  );
};

export default ComponentLoader;