import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import axios from "../utils/axiosConfig";

// New design pages
import { Suspense } from "react";
import Footer from "../router/Layout/component/Footer";
import { PublicRoute, PrivateRoute } from "./Layout";
import { privateRoutes, publicRoutes } from "./routerConfig";
import ComponentLoader from "shared/component-loader";
import { ROUTES_PATH } from "utils/constant";
import authInterceptor from "utils/axiosConfig/interceptors/authInterceptor";
import successHandler from "utils/axiosConfig/interceptors/successHandler";
import errorHandler from "utils/axiosConfig/interceptors/errorHandler";
import { AxiosError } from "axios";
import { Provider } from "react-redux";
import CommonDialog from "shared/common-dialog";
import { store } from "store/store";

axios.interceptors.request.use(authInterceptor, (error: any) =>
  Promise.reject(error)
);
axios.interceptors.response.use(
  successHandler,
  (error: AxiosError<unknown, any>) => {
    errorHandler(error);
  }
);

const Main = () => {
  return (
    <main className="pd_main_wrapper">
      <Provider store={store}>
        <BrowserRouter>
          <CommonDialog />
          <Routes>
            <Route element={<PrivateRoute />}>
              {privateRoutes.map(({ component: Component, ...restProps }) => (
                <Route
                  {...restProps}
                  element={
                    <Suspense fallback={<ComponentLoader />}>
                      <Component />
                    </Suspense>
                  }
                />
              ))}
            </Route>

            <Route element={<PublicRoute />}>
              {publicRoutes.map(
                ({ component: Component, ...restProps }: any) => (
                  <Route
                    {...restProps}
                    element={
                      <Suspense fallback={<ComponentLoader />}>
                        <Component />
                      </Suspense>
                    }
                  />
                )
              )}
              <Route
                path="*"
                element={<Navigate replace to={ROUTES_PATH.LOGIN} />}
              />
            </Route>
          </Routes>
          <Footer />
        </BrowserRouter>
      </Provider>
    </main>
  );
};

export default Main;
