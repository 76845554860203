// import { ArctileDetailsInt, ArticleResInt, ArticleStateInt } from "@/types/aritcleInterface";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  count: 0,
  authorCount: 0,
  max_score: 0,
  authors: [],
  isLoaded: false,
};

export const authorSlice = createSlice({
  name: "author",
  initialState,
  reducers: {
    getAuthors: (state, action: PayloadAction<any>) => {
      state.count = action.payload ? action.payload?.count : 0;
      state.authorCount = action.payload ? action.payload?.count : 0;

      state.max_score = action.payload ? action.payload.max_score : 0;
      state.authors = action.payload ? action.payload.result : [];
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload;
    },
  },
});
export const { getAuthors, setLoader } = authorSlice.actions;
export default authorSlice.reducer;
