import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import layoutReducer from "./reducers/layoutReducer"
import articleReducer from "./reducers/articleReducer";
import authorReducer from "./reducers/authorReducer";
import userReducer from "./reducers/userReducer";
import roleReducer from "./reducers/roleReducer";
import additionalReducer from "./reducers/additionalReducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    article:articleReducer,
    layout: layoutReducer,
    author:authorReducer,
    user:userReducer,
    role: roleReducer,
    privacy:additionalReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof configureStore>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
