/* eslint-disable no-fallthrough */
import { AxiosError } from "axios";
import axios from "../index";
import { store } from "../../../store/store";
import { refreshToken } from "../../../store/actions/authAction";
import * as commonService from "../../CommonService";

const handleAuthentication = async (error: any): Promise<void> => {
  const config = error.config;
  if (config.url === "/refresh") {
    commonService.forError("Not authenticated");
    // store.dispatch({ type: "auth/logout" });
    Promise.reject();
  } else {
    const res: any = await refreshToken(store.dispatch);
    if (res && res.accessToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${res.accessToken}`,
      };
      return axios(config);
    } else {
      // store.dispatch({ type: "auth/logout" });
      // return false
      return Promise.reject(new Error("Failed to refresh token"));
    }
  }
};

export default function errorHandler(error: any): Promise<any> {
  if (error?.message === "Network Error") {
    // return handleAuthentication(error)
    return Promise.reject<AxiosError>(error);
  }
  // const message = error?.message
  //   ? error.message
  //   : "Seems like something went wrong!";

  const message = error.response.data.msg
  ? error.response.data.msg : error.response.data.message ? error.response.data.message
  : error.message ? error.message : "Seems like something went wrong!";

  switch ((error?.response?.status)) {
    case 400:
      if (message === "jwt expired") return handleAuthentication(error);
      else commonService.forError(message);
      break;
    case 401:
      if (message === "Token has expired") return handleAuthentication(error);
      else commonService.forError(message);
       break;
    case 422:
     return handleAuthentication(error);
    case 404:
      commonService.forError(message);
      break;
    case 409:
      commonService.forError(message);
      break;
    case 500:
      commonService.forError(message);
      break;
    case 504:
      commonService.forError(
        "Sorry, could not access the external resource to refine the data for your request, please try again later!"
      );
      break;
    case 700:
      commonService.forError(message);
      break;
    default:
      commonService.forError(message ? message : "something went wrong");
      break;
  }
  return Promise.resolve(error);
}
