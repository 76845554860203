import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState, LoginRes, SignupRes } from "../types/authType";
import { storage } from "utils/constant";
import { RefreshRes } from "utils/OnboardInterface";

const token = localStorage.getItem("token");
const refresh_token = localStorage.getItem("refresh_token");
const userItem = localStorage.getItem("user");
let user = null;

if (userItem) {
  user = JSON.parse(userItem);
}

const initialState: AuthState = {
  token: token || "",
  refresh_token: refresh_token || "",
  user: user || null || undefined,
  isLoaded: false
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<LoginRes>) => {
      localStorage.setItem("token", action?.payload?.accessToken);
      localStorage.setItem("refresh_token", action.payload?.refreshToken);
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      state.token = action?.payload?.accessToken;
      state.refresh_token = action?.payload?.refreshToken;
      state.user = action?.payload.user;
    },
    signup: (state, action: PayloadAction<SignupRes>) => {
      state.token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
    },
    logout: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("user");
      state.token = "";
      state.refresh_token = "";
      state.user = "";
    },
    refreshToken: (state, action: PayloadAction<RefreshRes>) => {
      storage.set("token", action?.payload?.accessToken);
      state.token = action.payload.accessToken;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload;
    },
    userUpdate: (state, action: PayloadAction<any>) => {
      storage.set("user", action.payload);
      state.user = action.payload;
    }
  },
});
export const { login, signup, logout, refreshToken , setLoader , userUpdate } = authSlice.actions;

export default authSlice.reducer;
