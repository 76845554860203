/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "store/actions/authAction";
import { useAppDispatch, useAppSelector } from "store/hooks";
import logouticon from "assets/images/icons/Logout.svg";
import { isDialogOpen } from "utils/CommonService";
import { ROUTES_PATH } from "utils/constant";
import userIcn from "assets/images/40_40.svg";
import { RootState } from "store/store";
import { useSelector } from "react-redux";
import { toggleSideBar } from "store/reducers/layoutReducer";
import { useEffect, useState } from "react";

const Header = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { user } = useSelector((state: RootState) => state.auth);

  const { isSideBar } = useAppSelector((state: RootState) => state.layout);
  const [initialToggle, setInitialToggle] = useState(false);

  useEffect(() => {
    setInitialToggle(!initialToggle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSideBar]);
  const navigate = useNavigate();

  const handleConfirm = () => {
    isDialogOpen.onNext({
      open: true,
      data: {
        message: "Are you sure you want to logout?",
        title: "Confirmation",
      },
      cancelText: "Cancel",
      confirmText: "Okay",
      onCancel: () => isDialogOpen.onNext(false),
      onConfirm: () => {
        isDialogOpen.onNext(false);
        dispatch(logout());
      },
    });
  };

  const firstName =
    user?.first_name?.charAt(0)?.toUpperCase() + user?.first_name?.slice(1);
  const lastName =
    user?.last_name?.charAt(0)?.toUpperCase() + user?.last_name?.slice(1);

  const handleHideShow = () => {
    dispatch(toggleSideBar({ isSideBar: initialToggle }));
  };

  return (
    <>
      <header className="pd_home_header main-header">
        <nav className="navbar navbar-expand-lg">
          <div className="right-cont">
            <span className="head_btn_wrap">
              <div
                className={`burger_button ${isSideBar ? "syud" : ""}`}
                onClick={handleHideShow}
              >
                <div className="hamburger">
                  <input type="checkbox" />
                  <div className="hamburgerlines">
                    <span className="lines line1"></span>
                    <span className="lines line2"></span>
                    <span className="lines line3"></span>
                  </div>
                </div>
              </div>
              <div className="page-title">
                {pathname.includes(ROUTES_PATH.DASHBOARD)
                  ? "Dashboard"
                  : pathname.includes(ROUTES_PATH.AUTHORS)
                  ? "Authors"
                  : pathname.includes(ROUTES_PATH.PAGEPUBLISHED)
                  ? "Paper Published"
                  : pathname.includes(ROUTES_PATH.USER)
                  ? "Users"
                  : ""}{" "}
              </div>
            </span>
            <div className="account_prowrap">
              <span className="account_profile">
                <img
                  src={user?.image ? user?.image : userIcn}
                  alt="profile_acc"
                />
                <div className="site-dropdown">
                  <Dropdown>
                    <p>
                      {firstName} {lastName}
                    </p>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="toggle_btn"
                    ></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => navigate(ROUTES_PATH.PROFILE)}
                      >
                        My Profile
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handleConfirm}>
                        Logout{" "}
                        <span className="logout_iconimg">
                          <img
                            src={logouticon}
                            alt="logouticon"
                            width={24}
                            height={24}
                          />
                        </span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </span>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
export default Header;
