/* eslint-disable no-unreachable */
import { AppDispatch } from "../store";
import * as auth from "../reducers/authReducer";
import * as API from "../apiAction/authApi";
import { forError, forSuccess } from "utils/CommonService";
import { ForgotForm, ResetFormInt } from "utils/OnboardInterface";

export const adminLogin = (form: any) => async (dispatch: AppDispatch) => {
  try {
    const res: any = await API.login(form);
    if(res){
      dispatch(auth.login(res?.data));
      forSuccess("Login successfully.");
      return res;
    }
  } catch (err: any) {
    console.log(err);
  }
};

export const forgotPassword =
  (form: ForgotForm) => async (dispatch: AppDispatch) => {
    try {
      dispatch(auth.setLoader(true));
      const res: any = await API.forgotPasswordApi(form);
      if (res) {
        dispatch(auth.setLoader(false));
        return res.message;
      }
      return res;
    } catch (err: any) {
      dispatch(auth.setLoader(false));
      forError(err?.response?.data?.messages);
      console.log(err?.message);
    }
  };

export const logout = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(auth.logout());
    forSuccess("Logout successfully.");
    return true;
  } catch (err: any) {
    console.error("Logout error:", err);
    forError(err);
    return false;
  }
};

export const resetPassword =
  (form: ResetFormInt) => async (dispatch: AppDispatch) => {
    try {
      dispatch(auth.setLoader(true));
      const res: any = await API.resetPasswordApi(form);
      if (res) {
        dispatch(auth.setLoader(false));
        return res.message;
      }
      return res;
    } catch (err: any) {
      dispatch(auth.setLoader(false));
      forError(err?.response?.data?.messages);
      console.log(err?.message);
    }
  };
export const refreshToken = async (dispatch: AppDispatch) => {
  try {
    const res: any = await API.refreshToken();
    if (res) {
      dispatch(auth.refreshToken(res.data));
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};
