// import { ArctileDetailsInt, ArticleResInt, ArticleStateInt } from "@/types/aritcleInterface";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface HighlightInt {
  abstract: string | null;
  authors: string[] | null;
  title: string | null;
}

interface ArticleDetailRes {
  abstract: string | null;
  author_affiliations: any;
  authors: string[] | null;
  cite: string | null;
  doi: string | null;
  doi_link: string | null;
  image: any;
  keyword: string[] | null;
  pdf: any;
  pmcid: string | null;
  pmid: string | null;
  publish_date: string | null;
  title: string | null;
  uuid: string | null;
}

interface ResultInt {
  _score: number | null;
  abstract: string | null;
  authors: string[] | null;
  highlight: HighlightInt;
  id: string | null;
  pdf: string | null;
  pmid: string | null;
  title: string | null;
}
interface ArticleStateInt {
  count: number | null;
  max_score: number | null;
  result: ResultInt[];
  oneResult: ArticleDetailRes[];
  isLoaded: boolean;
  recentArticle: ResultInt[];
  studies: any;
  dashboardDetail: any;
  authorDetail: any;
  studyDetail: any;
}

interface ArticleResInt {
  count: number;
  max_score: number;
  result: any;
}

const initialState: ArticleStateInt = {
  count: 0,
  max_score: 0,
  result: [],
  oneResult: [],
  isLoaded: false,
  recentArticle: [],
  dashboardDetail: [],
  authorDetail: [],
  studies: [],
  studyDetail: null,
};

export const articleSlice = createSlice({
  name: "article",
  initialState,
  reducers: {
    getArictleDetails: (state, action: PayloadAction<ArticleResInt>) => {
      state.count = action.payload ? action.payload?.count : 0;
      state.max_score = action.payload ? action.payload.max_score : 0;
      state.result = action.payload ? action.payload.result : [];
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload;
    },
    getRecentArticleReducer: (state, action: PayloadAction<ArticleResInt>) => {
      state.recentArticle = action.payload.result;
    },
    getStudiesDetails: (state, action: PayloadAction<any>) => {
      state.studies = action.payload;
    },
    getSingleArticleDetails: (state, action: PayloadAction<any>) => {
      state.studyDetail = action.payload;
    },
    getDashboardDetailsReducer: (state, action: PayloadAction<any>) => {
      state.dashboardDetail = action.payload;
    },
    getAuthorDetailsReducer: (state, action: PayloadAction<any>) => {
      state.authorDetail = action.payload;
    },
    getArictleDetailsStatus: (state, action: PayloadAction<any>) => {
      state.result = state.result.map((e: any, i: number) =>
        i === action.payload.index
          ? { ...e, is_active: action.payload.value }
          : e
      );
    },
    updateDashBoardLocally: (state, action: PayloadAction<any>) => {
      state.result = state.result.filter(
        (list: any) => list.id !== action.payload
      );
      state.count = state.count ? state.count - 1 : 0;
    },
  },
});
export const {
  getArictleDetails,
  setLoader,
  getArictleDetailsStatus,
  getAuthorDetailsReducer,
  getRecentArticleReducer,
  getStudiesDetails,
  getSingleArticleDetails,
  updateDashBoardLocally,
  getDashboardDetailsReducer,
} = articleSlice.actions;
export default articleSlice.reducer;
