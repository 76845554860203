const Footer = () => {
  return (
    <footer className="pd_login_footer">
      <div className="pd_container">
        <div className="row">
          <div className="col-md-12">
            <div className="pd_footer_title">
              <h2>Papers Published Copyrights 2023</h2>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
