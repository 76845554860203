export const PUBLIC_PATH = {
  LOGIN: "/",
  FORGOTPASSWORD:"/forgot-password",
  RESETPASSWORD:"/reset-password",
};

export const PRIVATE_PATH = {
  DASHBOARD: "/dashboard",
  NEWRESEARCHPAPER: "/new-research-paper",
  EDITNEWRESEARCHPAPER: "/editnewresearchpaper",
  NEWRESEARCHPAPERURL:"/new-research-paperstep",
  AUTHORS:"/authors",
  PAGEPUBLISHED:"/pagepublished",
  USER:"/user",
  CREATEAUTHOR :"/create-author",
  EDITAUTHOR:"/edit-author",
  ADDUSER:"/adduser",
  EDITUSER:"/edituser",
  ROLE:"/admin",
  ADDROLE:"/add-admin",
  EDITROLE:"/edit-admin",
  PROFILE:"/profile",
  PRIVACYPOLICY:"/privacypolicy",
  TERMSCONDTION:"/terms"
  
};

export const ROUTES_PATH = {
  ...PUBLIC_PATH,
  ...PRIVATE_PATH,
};



export const SearchFilter = <T extends (...args: any[]) => any>(func: T) => {
  let timer: ReturnType<typeof setTimeout> | null;
  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 1000);
  };
};
export const pagePerOptions = [5, 10, 25];
export const limit = 10;

export const helpcontent = {
  welcome: "welcome",
  pramenu: "pramenu",
  definition: "definition",
  workarea: "workarea",
  individual: "individual",
  newreserveitem: "newreserveitem",
  newsite: "newsite",
  newproperty: "newproperty",
  editproperty: "editproperty",
  warningsms: "warningsms",
  reserveprofile: "reserveprofile",
  systemmethods: "systemmethods",
};

export const tableData1 = [
  "Custom Report 1",
  "Custom Report 2",
  "Sites",
  "Properties",
  "Phases Units Model",
  "Unit Address",
  "Model Allocation",
  "Definition",
  "Item Summary",
  "Item Details",
  "Individual Unit",
  "Expenditure Summary",
  "Expenditure Detail",
  "Expenditure History",
];

export const tableData2 = [
  "Saved Header",
  "Property Manager Sites",
  "Cash Manager Analysis Summary",
  "Saved Footer",
  "Property View",
  "Model Allocations",
];


export const storage = {
  set(key: string, value: string | object) {
    if (
      typeof value === "object" ||
      Array.isArray(value) ||
      Number.isInteger(value)
    ) {
      value = JSON.stringify(value);
    }
    const ISSERVER = typeof window === "undefined";
    if (!ISSERVER) localStorage.setItem(key, value);
  },
  get(key: string, defaultValue?: string | null | undefined) {
    let data: string | null = "";
    if (typeof window !== "undefined") {
      // Perform localStorage action
      data = localStorage.getItem(key);
    }

    try {
      let parsed = data ? JSON.parse(data) : null;

      return parsed !== null ? parsed : defaultValue;
    } catch (e) {
      return data !== null ? data : defaultValue;
    }
  },
  remove(key: string) {
    return localStorage.removeItem(key);
  },
  clear() {
    return localStorage.clear();
  },
};