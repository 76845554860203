// import { ArctileDetailsInt, ArticleResInt, ArticleStateInt } from "@/types/aritcleInterface";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  count: 0,
  results: [],
  isLoaded: false,
};

export const userSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    getRoles: (state, action: PayloadAction<any>) => {
      state.results = action.payload ? action.payload.result : [];
      state.count = action.payload ? action.payload.count : 0;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload;
    },
  },
});
export const { getRoles, setLoader } = userSlice.actions;
export default userSlice.reducer;
